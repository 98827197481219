import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
    path: '/',
    name: 'Index_JobSeeker',
    component: () => import('../views/Index_JobSeeker.vue')
  },
  {
    path: '/indexDetails',
    name: 'IndexDetails',
    component: () => import('../views/indexDetails/indexDetails.vue')
  },
  {
    path: '/myResume',
    name: 'MyResume',
    meta: {
      auth: true
    },
    component: () => import('../views/MyResume/myResume.vue')
  },
  {
    path: '/personalCenter',
    name: 'PersonalCenter',
    meta: {
      auth: true
    },
    component: () => import('../views/PersonalCenter/PersonalCenter.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/about.vue')
  },
  {
    path: '/searchJob',
    name: 'SearchJob',
    component: () => import('../views/searchJob/searchJob.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import('../views/Password.vue')
  },
  {
    path: '/recruit/index',
    name: 'Recruit',
    alias: '/recruit',
    component: () => import('../views/recruit/Index.vue')
  },
  {
    path: '/recruit/search',
    name: 'Search',
    component: () => import('../views/recruit/Search.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/news/Index.vue')
  },
  {
    path: '/newDetail',
    name: 'NewDetail',
    component: () => import('../views/news/newDetail.vue')
  },
  {
    path: '/recruit/show',
    name: 'Show',
    component: () => import('../views/recruit/show.vue')
  },
  {
    path: '/recruitCenter/wallet',
    name: 'Wallet',
    component: () => import('../views/recruit/Wallet.vue')
  },
  {
    path: '/chart',
    name: 'Chart',
    component: () => import('../views/chart.vue')
  },
  {
    path: '/resume',
    name: 'Resume',
    component: () => import('../views/recruit/resumeList.vue')
  },
  {
    path: '/recruitCenter',
    name: 'Center',
    meta: {
      auth: true
    },
    component: () => import('../views/recruit/Center.vue'),
    children: [{
        path: '',
        name: 'CenterIndex',
        component: () => import('../views/recruit/CenterIndex.vue')
      },
      {
        path: 'releaseed',
        name: 'Releaseed',
        component: () => import('../views/recruit/Releaseed.vue')
      },
      {
        path: 'release',
        name: 'Release',
        component: () => import('../views/recruit/Release.vue')
      },
      {
        path: 'collection',
        name: 'Collection',
        component: () => import('../views/recruit/Collection.vue')
      },
      {
        path: 'password',
        name: 'Password',
        component: () => import('../views/recruit/Password.vue')
      },

      {
        path: 'resume',
        name: 'Resume',
        component: () => import('../views/recruit/Resume.vue')
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to,from,next)=>{
  if(!to.meta.auth){
    next();
  }else if(!sessionStorage.getItem('userInfo')){
    next('/register');
  } else {
    next();
  }
})

export default router
